import * as React from 'react'
import { PricingPlanFeature } from './PricingPlanFeature'

/**
 * The features to display for pricing plans.
 */
export interface Features {
    codeSearch: boolean
    codeHostIntegration: boolean
    api: boolean
    selfHosted: boolean
    userAndAdminRoles: boolean
    singleSignOn: boolean
    multipleCodeHosts: boolean
    repositoryPermissions: boolean
    deploymentMetricsAndMonitoring: boolean
    customBranding: boolean
    onlineTraining: boolean
}

export interface FeatureInfo {
    label: string
    url?: string
    description: string
}

const FEATURE_INFO: Record<keyof Features, FeatureInfo> = {
    codeSearch: {
        label: '代码搜索',
        description: '智能代码搜索,支持跨仓库方法跳转和方法定义查找',
    },
    codeHostIntegration: {
        label: '1个代码平台集成',
        description: '集成GitHub或者GitLab',
    },
    api: {
        label: 'API访问',
        description: '安全快速高可用API访问,帮助集成内部系统',
    },
    selfHosted: {
        label: '私有部署',
        description: '通过Docker Compose或者Kubernetes部署到自己的机器',
    },
    singleSignOn: {
        label: '单点登录',
        description: '单点登录系统,支持: SAML, OAuth, SSO等',
    },
    userAndAdminRoles: {
        label: '用户管理/权限管理',
        description: '支持管理用户访问权限等',
    },
    multipleCodeHosts: {
        label: '多代码平台集成',
        description: '',
    },
    repositoryPermissions: {
        label: '代码仓库权限管理',
        description: '精细化管理代码仓库权限',
    },
    deploymentMetricsAndMonitoring: {
        label: '全方位监控',
        description: '性能,可用性监控。及时发现问题',
    },
    customBranding: {
        label: 'UI定制化',
        description: '自定义部分UI',
    },
    onlineTraining: {
        label: '在线使用培训',
        description: '全方面使用培训',
    },
}

const FEATURE_ORDER: (keyof Features)[] = [
    'codeSearch',
    'codeHostIntegration',
    'api',
    'selfHosted',
    'multipleCodeHosts',
    'repositoryPermissions',
    'userAndAdminRoles',
    'singleSignOn',
    'deploymentMetricsAndMonitoring',
    'onlineTraining',
    'customBranding',
]

interface Props {
    className?: string

    name: string
    planProperties: React.ReactNode
    price: React.ReactNode
    features: Features

    buttonLabel: string
    buttonClassName: string
    buttonOnClick?: () => void
    buttonHref: string
}

/**
 * A pricing plan on the pricing page.
 */
export const PricingPlan: React.FunctionComponent<Props> = ({
    className,

    name,
    price,
    planProperties,
    features,
    buttonLabel,
    buttonClassName,
    buttonOnClick,
    buttonHref,
}) => {
    const button = (
        <a
            className={`pricing-plan__button btn ${buttonClassName} w-100 mx-auto my-0 justify-content-center text-center d-flex`}
            href={buttonHref}
            target="_blank"
            onClick={buttonOnClick}
        >
            {buttonLabel}
        </a>
    )

    return (
        <div className={`pricing-plan card ${className}`}>
            <h2 className="card-title mt-3 mb-1 text-center pricing-plan__title">{name}</h2>
            <div className="card-body pt-3 text-center d-flex flex-column align-items-center">
                {button}
                <div className="mt-4 mb-2 pb-2 pricing-plan__price text-muted">{price}</div>
                {planProperties}
            </div>
            <ol className="pricing-plan__features list-group list-group-flush py-3">
                {FEATURE_ORDER.map(feature => (
                    <>
                        <PricingPlanFeature
                            key={feature}
                            info={FEATURE_INFO[feature]}
                            value={features[feature]}
                            tag="li"
                            className="list-group-item bg-transparent border-0 px-0"
                        />
                    </>
                ))}
            </ol>
            {/* <div className="card-body">{button}</div> */}
        </div>
    )
}
