import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import { ContentSection } from '../components/content/ContentSection'
import Layout from '../components/Layout'
import { Features, PricingPlan } from '../components/pricing/PricingPlan'
import { PricingPlanFeature } from '../components/pricing/PricingPlanFeature'
import { PricingPlanProperty } from '../components/pricing/PricingPlanProperty'

const DESCRIPTION = 'CodeIN是智能代码搜索平台,可根据不同的需求选择不同的套餐'

/** The Personal feature set. */
const STARTER_FEATURES: Features = {
    codeSearch: true,
    codeHostIntegration: true,
    api: true,
    selfHosted: false,
    userAndAdminRoles: false,
    singleSignOn: false,
    multipleCodeHosts: false,
    repositoryPermissions: false,
    deploymentMetricsAndMonitoring: false,
    customBranding: false,
    onlineTraining: false,
}

/** The Team feature set. */
const TEAM_FEATURES: Features = {
    ...STARTER_FEATURES,
    selfHosted: true,
    multipleCodeHosts: true,
    singleSignOn: true,
    userAndAdminRoles: true,
}

const ENTERPRISE_FEATURES: Features = {
    codeSearch: true,
    codeHostIntegration: true,
    api: true,
    selfHosted: true,
    singleSignOn: true,
    userAndAdminRoles: true,
    multipleCodeHosts: true,
    repositoryPermissions: true,
    deploymentMetricsAndMonitoring: true,
    customBranding: true,
    onlineTraining: true,
}

export default ((props: any) => (
    <Layout location={props.location}>
        <div className="text-dark">
            <Helmet>
                <title>CodeIN - 价格方案</title>
                <meta name="twitter:title" content="CodeIN - 价格方案" />
                <meta property="og:title" content="CodeIN - 价格方案" />
                <meta name="twitter:description" content={DESCRIPTION} />
                <meta property="og:description" content={DESCRIPTION} />
                <meta property="og:image" content="https://home.codein.dev/codein.png" />
                <meta name="description" content={DESCRIPTION} />
                <link rel="icon" type="image/png" href="/favicon.png" />
            </Helmet>
            <div className="pricing-page mt-2">
                <ContentSection className="hero-section text-center py-5">
                    <h1 className="display-2 font-weight-bold">价格方案</h1>
                    <h3 className="display-4">可根据您的企业规模和需求，选择不同的产品套餐</h3>
                </ContentSection>
                <div className="container-fluid pricing-page__plans">
                    <div className="row pt-4">
                        <div className="col-md-4 mx-auto mb-4">
                            <PricingPlan
                                className="pricing-page__plan"
                                name="免费版"
                                price={<div className="text-center">0元/月</div>}
                                planProperties={
                                    <>
                                        <PricingPlanProperty>仓库数量100</PricingPlanProperty>
                                        <PricingPlanProperty className="mt-3">
                                            微信客服
                                            <br />
                                            &nbsp;
                                        </PricingPlanProperty>
                                    </>
                                }
                                features={STARTER_FEATURES}
                                buttonLabel="立即试用"
                                buttonClassName="btn-outline-primary"
                                buttonHref="https://codein.dev"
                            />
                        </div>

                        <div className="col-md-4 mx-auto mb-4">
                            <PricingPlan
                                className="pricing-page__plan"
                                name="团队版"
                                price={<div className="text-center">联系我们</div>}
                                planProperties={
                                    <>
                                        <PricingPlanProperty>仓库数量1000</PricingPlanProperty>
                                        <PricingPlanProperty className="mt-3">
                                            微信专属客服/24小时电话客服/使用文档
                                            <br />
                                            &nbsp;
                                        </PricingPlanProperty>
                                    </>
                                }
                                features={TEAM_FEATURES}
                                buttonLabel="联系我们"
                                buttonClassName="btn-primary"
                                buttonHref="/contact/request-info/?form_submission_source=pricing-team"
                            />
                        </div>

                        <div className="col-md-4 mx-auto mb-4">
                            <PricingPlan
                                className="pricing-page__plan"
                                name="企业版"
                                price={<div className="text-center">联系我们</div>}
                                planProperties={
                                    <>
                                        <PricingPlanProperty>不限制</PricingPlanProperty>
                                        <PricingPlanProperty className="mt-3">
                                            工程师咨询/微信专属客服/24小时电话客服/使用文档
                                            <br />
                                        </PricingPlanProperty>
                                    </>
                                }
                                features={ENTERPRISE_FEATURES}
                                buttonLabel="联系我们"
                                buttonClassName="btn-primary"
                                buttonHref="/contact/request-info/?form_submission_source=pricing-enterprise"
                            />
                        </div>
                    </div>
                    <hr className="my-4" />
                </div>
            </div>

            <ContentSection className="my-6">
                <div className="row">
                    <div className="col-md-6 pr-md-5">
                        <h3 className="display-4 font-weight-bold">开始试用CodeIN代码搜索</h3>
                        <p>可快速搜索开源仓库和私有仓库</p>
                    </div>
                    <div className="col-md-6 pt-3 align-self-center text-center">
                        <Link
                            className="btn btn-primary mx-2 mb-3"
                            to="https://codein.dev"
                            title="试用"
                            target="_blank"
                        >
                            试用
                        </Link>
                    </div>
                </div>
            </ContentSection>
        </div>
    </Layout>
)) as React.FunctionComponent<any>
